// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/LocaleProvider.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/LocaleProvider.tsx");
  import.meta.hot.lastModified = "1721054464786.8135";
}
// REMIX HMR END

import { useContext } from "react";
import { createContext } from "react";
const Context = createContext(null);
export const LocaleContextProvider = ({
  locales,
  children
}) => {
  const value = {
    locales
  };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
_c = LocaleContextProvider;
const throwIfNoProvider = () => {
  throw new Error("Please wrap your application in a LocaleContextProvider.");
};
export const useLocales = () => {
  _s();
  const {
    locales
  } = useContext(Context) ?? throwIfNoProvider();
  return locales;
};
_s(useLocales, "gDsCjeeItUuvgOWf1v4qoK9RF6k=");
var _c;
$RefreshReg$(_c, "LocaleContextProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;